<template>
  <div class="newsDetails">
    <el-container>
      <el-header>
        <div class="headerbox">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb-box">
            <el-breadcrumb-item :to="{ path: '/' }"><span class="breadcrumb">首页</span></el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/newscenter' }"><span class="breadcrumb">新闻中心</span></el-breadcrumb-item>
            <el-breadcrumb-item><span class="breadcrumb">文章详情</span></el-breadcrumb-item>
          </el-breadcrumb>

        </div>
      </el-header>
      <div >
        <div class="news-contain" v-loading="loading">
          <div class="left-contain">
            <span class="news-title">{{newsDetails.imgName }}  &nbsp; {{ newsDetails.imgTitle == null?'':newsDetails.imgTitle}}</span><br>
            <div class="left-value"  v-html="newsDetails.data" ></div>
          </div>
          <div class="right-contain">
            <div class="news-hot">
              <div class="news-right-title">热点</div>
              <div class="type_item" v-for="(item, index) in valueNews" :key="index">
                <el-card shadow="hover" class="card">
                  <div  @click="goToDtails(item)">
                    <div class="imgbox">
                      <img alt="" :src="`https://www.yuanzhoudt.com/dev-api` + item.imgUrl">
                    </div>
                    <div class="textbox">
                      <h3>{{ item.imgName }}</h3>
                    </div>
                    <p  class="text-clamp">{{ item.imgText }}</p>
                  </div>
                </el-card>
              </div>
            </div>
            <div class="news-label">
              <div class="news-right-title">标签</div>
              <div class="news-labels">
                <span v-for="(item,index) in newsTabs">
                  <el-button type="text" @click="goToNewsCenter(item)">{{ item.dictLabel }}</el-button>
                </span>
              </div>
            </div>
            <div class="news-hot">
              <div class="news-right-title">推荐</div>
              <div class="type_item" v-for="(item, index) in valueNews" :key="index">
                <el-card shadow="hover" class="card">
                  <div  @click="goToDtails(item)">
                    <div class="imgbox">
                      <img alt="" :src="`https://www.yuanzhoudt.com/dev-api` + item.imgUrl">
                    </div>
                    <div class="textbox">
                      <h3>{{ item.imgName }}</h3>
                    </div>
                    <p  class="text-clamp">{{ item.imgText }}</p>
                  </div>
                </el-card>
              </div>
            </div>

          </div>
        </div>
      </div>


    </el-container>
  </div>
</template>

<script>
import {selectXWZXListAnoy} from "@/api/newscenter";
import {getDicts} from "@/api/dict";
import {selectXWZXImagesListTOP3Anoy} from "@/api/collaborators";
export default {
  name: "newscenterDetails",
  data() {
    return {
      newsId:'',
      newsDetails:{},
      valueNews:[],
      loading:false,
      newsTabs:[]
    };
  },
  created() {
    //获取路由参数
    this.newsId = this.$route.query.id
  },
  mounted() {
    this.getXWZNewsList()
    this.getXWZXImagesListTOP3()
    getDicts('yzsk_web_news_tabs').then(response => {
      this.newsTabs = response.data
    })
  },
computed: {
  // formattedData() {
  //   return this.newsDetails.data.replace(/<img[^>]+>/g, match => {
  //     return match.replace(/style="[^"]*"/, 'style="width: 100%; height: auto; display: block;"');
  //   });
  // }
},
  methods: {
    goToNewsCenter(item){
      this.$router.push({
        name: 'newscenter',
        query: {
          activeName: item.dictValue
        }
      })

    },
    /** 点击跳转详情*/
    goToDtails(item) {
      this.newsId = item.id
      this.getXWZNewsList()
    },
    /** 获取新闻详细信息*/
    getXWZNewsList() {
      this.loading = true
      selectXWZXListAnoy({'imgId':this.newsId}).then(response => {
          if (response.code && response.code == 200){
            this.newsDetails = response.data
          }else {
            this.$message.error(response.msg)
          }
          this.loading = false
      })
    },
    getXWZXImagesListTOP3() {
      selectXWZXImagesListTOP3Anoy().then(response => {
        if (response.rows){
          this.valueNews = response.rows
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.newsDetails {
  background: #ffffff;
  width: 100%;
  margin-top: 60px;

  .news-contain {
    width: auto;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* 允许在小屏幕上换行 */

    .right-contain {
      width: 30%; /* 右边的宽度为容器宽度的30% */
      margin: 0 1rem;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 2rem;
      }

      .news-hot {
        margin-top: 1rem;

        .news-right-title {
          height: 3rem;
          border-bottom: 1px solid #DFE2E4;
          font-size: 1.2rem;
          font-weight: 500;
          color: #091021;
          line-height: 1.5rem;
        }

        .type_item {
          width: 100%;
          height: auto;
          @media (max-width: 768px) {
            width: 100%;
          }

          .imgbox {
            margin: 0 auto;

            img {
              width: 100%;
              display: block;
              border-radius: 1rem;
            }
          }

          .card {
            margin-top: 1rem;
            transition: all 0.5s linear;

            .textbox {
              margin-bottom: 1rem;
              display: flex;

              .iconfont {
                color: red;
                font-size: 1rem;
              }

              h3 {
                color: #333;
                font-size: 1rem;
                margin-left: 10px;
              }
            }

            p {
              font-size: .875rem;
              line-height: 1.5rem;
              margin-left: 1rem;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* 显示的行数 */
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .card:hover {
            transform: translate(0, -10px);

            img {
              border-radius: 1rem;
              transform: scale(1.2);
            }
          }
        }
      }

      .news-label {
        margin-top: 3rem;

        .news-right-title {
          height: 3rem;
          border-bottom: 1px solid #DFE2E4;
          font-size: 1.2rem;
          font-weight: 500;
          color: #091021;
          line-height: 1.5rem;
        }

        .news-labels {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          span {
            padding: .5rem 1rem;
            margin-top: 1rem;
            border-radius: .5rem;
            border: 1px solid rgba(9, 15, 33, .3);
            line-height: 1rem;
            text-align: center;
            font-size: 1rem;
            font-weight: 400;
            margin-right: .5rem;

            a {
              color: rgba(9, 15, 33, .5);
            }
          }
        }
      }

      .news-recommend {
        /* 保留空样式 */
      }
    }

    .left-contain {
      text-align: center;
      width: 65%; /* 左边的宽度为容器宽度的65% */
      //margin: 0 1rem;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 1rem;
      }

      .news-title {
        width: 100%;
        font-size: 2rem;
        font-weight: 500;
        color: #091021;
        line-height: 3rem;
        text-align: left;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
      }

      .left-value {
        text-align: left;
        display: inline-block;
        vertical-align: top;
        background-color: rgb(255, 255, 255);
        box-shadow: rgb(189, 189, 189) 0px 0px 4px;
        border-width: 0px;
        border-radius: 5px;
        overflow: hidden;
        align-self: flex-start;
        flex: 0 0 auto;
        box-sizing: border-box;

      }

      /* 媒体查询：针对小屏幕设备 */
      @media (max-width: 768px) {
        .left-value {
          width: 100%; /* 宽度占满整个容器 */
        }
        ::v-deep .left-value p img {
          max-width: 100% !important; /* 图片最大宽度为100% */
          height: auto !important;
        }
      }
    }
  }


  .headerbox {
    width: 110rem;
    height: 3.125rem;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 100%;
    }

    .breadcrumb-box {
      float: left !important;
      height: 3.125rem !important;
      line-height: 3.125rem !important;
      margin-right: 1.25rem !important;
      font-size: .875rem !important;
      font-weight: 400 !important;
      color: rgba(9, 15, 33, 1) !important;
    }
  }

  .breadcrumb {
    float: left;
    height: 3.125rem;
    line-height: 3.125rem;
    margin-right: 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    color: rgba(9, 15, 33, 1);
  }

  .breadcrumb:hover {
    border-bottom: unset !important;
    color: #04aaeb !important;
    background: transparent !important;
  }
}

</style>
<style>

</style>

